<template>
  <div class="mini_game_bet">
    <div class="mini_game">
      <div class="m1 dmr2">
        <div class="mini_fram">
          <div class="markeos"></div>
          <leisure-game-ifram-view>
            <iframe id="donghang-gamefram" scrolling="no" frameborder="0"
                    src="https://ntry.com/scores/speedkeno/live.php"></iframe>
          </leisure-game-ifram-view>
        </div>

      </div>
      <div class="m2">
        <div class="game_tab">
          <leisure-bet-masker :show-masker="showMasker"></leisure-bet-masker>
          <leisure-timer-comp
              ref="ltimer"
              :leisure-game-info="leisureGameInfo"
              @timerStop="timerStop" @refreshTimer="refreshTimer">
            <span slot="title" class="text-orange">스피드키노 </span>
          </leisure-timer-comp>

          <!--선택부분-->
          <div class="mini_bet_box">
            <div class="mg">
              <span class="t">홀/짝</span>
              <div class="mg_btn b2 bg-blue"
                   :class="{'active_minigame':selectedInfo.selectedAttr === leisureConst.LEISURE_ATTRIBUTE_SPEEDKINO_ODDEVEN && selectedInfo.selectedWay === 1}"
                   @click="selected(leisureConst.LEISURE_ATTRIBUTE_SPEEDKINO_ODDEVEN,leisureGameInfo.leisureGame.id,1, oddevenConfig.odds1, oddevenConfig,'홀')">
                <div class="r rblue">
                  <span class="n">홀</span>
                  <span class="b">{{ oddevenConfig.odds1 }}</span>
                </div>
              </div>
              <div class="mg_btn b2 bg-red"
                   :class="{'active_minigame':selectedInfo.selectedAttr === leisureConst.LEISURE_ATTRIBUTE_SPEEDKINO_ODDEVEN && selectedInfo.selectedWay === 2}"
                   @click="selected(leisureConst.LEISURE_ATTRIBUTE_SPEEDKINO_ODDEVEN,leisureGameInfo.leisureGame.id,2,  oddevenConfig.odds2, oddevenConfig,'짝')">
                <div class="r rred">
                  <span class="n">짝</span>
                  <span class="b">{{ oddevenConfig.odds2 }}</span>
                </div>
              </div>
            </div>
            <div class="mg">
              <span class="t">오버/언더</span>
              <div class="mg_btn b2 bg-blue"
                   :class="{'active_minigame':selectedInfo.selectedAttr === leisureConst.LEISURE_ATTRIBUTE_SPEEDKINO_OVERUNDER && selectedInfo.selectedWay === 2}"
                   @click="selected(leisureConst.LEISURE_ATTRIBUTE_SPEEDKINO_OVERUNDER,leisureGameInfo.leisureGame.id,2, overunderConfig.odds2, overunderConfig ,'언더')">
                <div class="r rblue">
                  <span class="n">언더</span>
                  <span class="b">{{ overunderConfig.odds2 }}</span>
                </div>
              </div>
              <div class="mg_btn b2 bg-red"
                   :class="{'active_minigame':selectedInfo.selectedAttr === leisureConst.LEISURE_ATTRIBUTE_SPEEDKINO_OVERUNDER && selectedInfo.selectedWay === 1}"
                   @click="selected(leisureConst.LEISURE_ATTRIBUTE_SPEEDKINO_OVERUNDER,leisureGameInfo.leisureGame.id,1, overunderConfig.odds1, overunderConfig,'오버')">
                <div class="r rred">
                  <span class="n">오버</span>
                  <span class="b">{{ overunderConfig.odds1 }}</span>
                </div>
              </div>

            </div>
          </div>

          <!--파워볼 베팅카트-->
          <leisure-bet-cart-comp
              ref="betCart" :kind="kind"
              :selected-info="selectedInfo">
            키노
          </leisure-bet-cart-comp>

        </div>
      </div>
      <div class="m3">
        <!--최근 배팅내역-->
        <leisure-lately-bet-list-comp
            ref="latelyBetList"
            :kind="kind"></leisure-lately-bet-list-comp>
      </div>


    </div>
  </div>
</template>

<script>
import LeisureTimerComp from "@/views/afront/leisure/LeisureTimerComp";
import LeisureBetCartComp from "@/views/afront/leisure/LeisureBetCartComp";
import LeisureBetMasker from "@/views/afront/leisure/LeisureBetMasker";
import LeisureLatelyBetListComp from "@/views/afront/leisure/LeisureLatelyBetListComp";
import LeisureGameIframView from "@/views/afront/leisure/LeisureGameIframView";

import {RECEIVE_CURRENTLEISUREGAMECOMP, RECEIVE_CURRENTLEISUREGAMEID} from "@/store/mutation-types";
import leisureConst from "@/common/leisureConst";
import {leisureGameMixin} from "@/common/mixin";
import {getLeisureGameAttributeConfig} from "@/network/leisureRequest";

export default {

  name: "SpeedKino",
  mixins: [leisureGameMixin],
  components: {
    LeisureGameIframView,
    LeisureLatelyBetListComp, LeisureBetMasker, LeisureBetCartComp, LeisureTimerComp,
  },
  data() {
    return {
      kind: leisureConst.LEISURE_KIND_SPEEDKINO,
    }
  },
  methods: {
    initLeisureGameArttributeConfig() {
      getLeisureGameAttributeConfig().then(res => {
        if (res.data.success) {
          this.attrConfig = res.data.data

          //배당설정
          this.oddevenConfig = this.attrConfig.find(attr => {
            return (attr.idx === leisureConst.LEISURE_ATTRIBUTE_SPEEDKINO_ODDEVEN)
          })

          this.overunderConfig = this.attrConfig.find(attr => {
            return (attr.idx === leisureConst.LEISURE_ATTRIBUTE_SPEEDKINO_OVERUNDER)
          })
        }
      })
    },
  },
  created() {
    this.initKindConfig(this.kind)
    this.$store.commit(RECEIVE_CURRENTLEISUREGAMECOMP, leisureConst.LEISURE_COMP_EOS);
    this.$store.commit(RECEIVE_CURRENTLEISUREGAMEID, this.kind);
  },
}
</script>


<style scoped>
@media screen and (max-width: 540px) {
  .dmr2 {
    min-height: 400px !important;
  }
}

@media screen and (max-width: 414px) {
  .dmr2 {
    min-height: 360px !important;
  }
}

@media screen and (max-width: 375px) {
  .dmr2 {
    min-height: 350px !important;
  }
}

@media screen and (max-width: 360px) {
  .dmr2 {
    min-height: 350px;
  }
}
</style>